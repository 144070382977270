import React from 'react'

const Footer = () => {
  return (
    <footer>
      <p>© 2024 | Designed & coded by Amberly Sandoval</p>
    </footer>
  );
}

export default Footer